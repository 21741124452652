import { useContext } from "react";
import { useMutation } from "react-apollo";
import { AppContext } from "../context/app";

export default function useCustomMutation(mutation, options) {
  const context = useContext(AppContext);
  return useMutation(mutation, {
    context: {
      headers: {
        "x-tenant-id": context.state?.tenantId,
      },
    },
    ...options,
  });
}
