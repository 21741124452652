export const jpeg = '.jpeg';
export const mp3 = '.mp3';
export const jpg = '.jpg';
export const png = '.png';
export const mp4 = '.mp4';
export const gif = '.gif';

export const JPG = '.JPG';
export const JPEG = '.JPEG';
export const PNG = '.PNG';
export const MP3 = '.MP3';
export const MP4 = '.MP4';
export const GIF = '.GIF';

export const PDF = '.PDF';
export const pdf = '.pdf';

export const image = [jpeg, jpg, png, gif, JPEG, JPG, PNG, GIF];
export const audio = [mp3, MP3];
export const video = [mp4, MP4];
export const documents = [pdf, PDF];
