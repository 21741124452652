import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

function loadLocaleData(locale) {
  switch (locale) {
    case "es":
      return import("../../i18n/es.json");
    default:
      return import("../../i18n/en.json");
  }
}

export default function IntlSetup({ children, locale, override }) {
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    async function load() {
      const loaded = await loadLocaleData(locale);
      setMessages({ ...loaded, ...override });
    }

    load();
  }, [locale, override]);

  if (!messages) {
    return null;
  }

  return (
    <IntlProvider messages={messages} defaultLocale="en" locale={locale}>
      {children}
    </IntlProvider>
  );
}
