import gql from "graphql-tag";

const CLIENT_MESSAGE = gql`
  mutation ClientMessage($input: ClientMessageInput!) {
    clientMessage(input: $input) {
      success
    }
  }
`;

const GET_MESSAGES = gql`
  query GetMessages($scid: String, $offset: Int) {
    messages(scid: $scid, offset: $offset) {
      id
      scid
      source
      content {
        type
        value
        caseList {
          title
          options {
            label
            option
          }
        }
      }
    }
  }
`;

const SUBSCRIPTION_MESSAGE_ADDED = gql`
  subscription onMessageAdded($scid: String!) {
    messageAdded(scid: $scid) {
      id
      scid
      source
      content {
        type
        value
        caseList {
          title
          options {
            option
            label
          }
        }
      }
    }
  }
`;

export { CLIENT_MESSAGE, GET_MESSAGES, SUBSCRIPTION_MESSAGE_ADDED };
