import { Box, Button, List, ListItem, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import React, { Component, useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { image } from '../utils/constant';
import { AppContext } from '../../context/app';
import { useCustomMutation } from '../../hooks';
import {
  CLIENT_MESSAGE,
  GET_MESSAGES,
  SUBSCRIPTION_MESSAGE_ADDED,
} from '../../queries/index';
import {
  getMessageContent,
  parseMessage,
  messageTextTransform,
} from '../../tools/index';
import { messageListStyle } from './style';
import './style.css';

const MessageItem = ({
  messageContainerClass,
  message,
  classes,
  flexEnd,
  fontStyle,
  isButton,
  agentLogo,
}) => {
  const [clientMessage] = useCustomMutation(CLIENT_MESSAGE);

  const context = useContext(AppContext);

  const { scid, name, contact, instance, question, buttons } = context.state;

  const handleButtonClick = async (event) => {
    const message = await getMessageContent(event);
    try {
      await clientMessage({
        variables: {
          input: {
            scid,
            name,
            contact,
            source: 'client',
            platform: 'web',
            instance,
            content: message,
            attrs: {
              contact: {
                firstName: name,
                email: contact,
                question: question,
              },
            },
          },
        },
        refetchQueries: ['GetMessages'],
      });
    } catch (e) {
      alert(e);
    }
  };

  const { source } = message;
  let { content } = message;

  const regexUrl =
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;

  if (
    regexUrl.test(content.value) &&
    image.some((ext) => content.value.includes(ext))
  )
    content.type = 'image';

  return (
    <>
      <React.Fragment key={scid}>
        <ListItem className={classNames(flexEnd, classes.listItem)}>
          {content.caseList.options.length > 0 ? (
            <>
              <Box className={classes.messageContainerT}>
                <div className={classes.messageContainerButtons}>
                  <Typography
                    gutterBottom
                    key={content.value}
                    className={classes.font}
                  >
                    {content.caseList.title}
                  </Typography>
                </div>
                <div>
                  {content.caseList.options.map((element) => (
                    <React.Fragment>
                      {buttons ? (
                        <Button
                          key={element.label}
                          className={`${classes.buttons} buttons`}
                          onClick={() => {
                            handleButtonClick(element.label);
                          }}
                          variant="contained"
                        >
                          {element.label}
                        </Button>
                      ) : (
                        <Box className={classes.case} px={2}>
                          <Typography
                            className={fontStyle}
                            key={content.value}
                            gutterBottom
                          >
                            {element.label}
                          </Typography>
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                {source === 'bot' && agentLogo ? (
                  <Avatar src={agentLogo}></Avatar>
                ) : null}
              </Box>
            </>
          ) : (
            <>
              {source === 'bot' && agentLogo ? (
                <Box>
                  <Avatar className={classes.logoMark} src={agentLogo}></Avatar>
                </Box>
              ) : null}
              {content.type.startsWith('image') ? (
                <img
                  src={content.value}
                  style={{
                    width: '20rem',
                    minWidth: 'fit',
                    maxWidth: '20rem',
                    minHeight: 'fit',
                    maxHeight: '18rem',
                    objectFit: 'contain',
                  }}
                  alt="imagePreview"
                />
              ) : (
                <Box className={messageContainerClass}>
                  <Box key={content.value} className={fontStyle}>
                    <Typography
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html: messageTextTransform(
                          parseMessage(content.value)
                        ),
                      }}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </ListItem>
      </React.Fragment>
    </>
  );
};

class MessageListView extends Component {
  constructor(props) {
    super(props);
    this.messagesEnd = null;
    this.listRef = React.createRef()
  }

  componentDidMount() {
    this.props.subscribeToMore();
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.listRef) {
      this.listRef.current.scrollTop = this.listRef.current.scrollHeight;
    }
  }

  render() {
    const { data, classes, agentLogo } = this.props;
    var messages = data.messages;
    messages = messages.filter(
      (message, index, self) =>
        index === self.findIndex((m) => m.id === message.id)
    );

    return (
      <List
        ref={this.listRef}
        className={classes.root}
        style={{
          padding: 10,
          overflowY: 'auto',
          scrollBehavior: 'smooth',
          boxSizing: 'border-box'
        }}
      >
        {messages.map((message) => {
          const oddEvenClasses =
            message?.source === 'client'
              ? message.content.type.startsWith('image')
                ? {
                  messageContainerClass: classes.messageImage,
                  imageClass: classes.imageRight,
                  flexEnd: classes.flexEnd,
                  fontStyle: classes.fontRight,
                }
                : {
                  messageContainerClass: `${classes.messageContainerRight} client-background-messages`,
                  imageClass: classes.imageRight,
                  flexEnd: classes.flexEnd,
                  fontStyle: `${classes.fontRight} client-font`,
                }
              : {
                messageContainerClass: `${classes.messageContainer} agent`,
                fontStyle: `${classes.fontLeft} agent-font`,
              };

          return (
            <MessageItem
              key={message.id}
              isButton={true}
              message={message}
              classes={classes}
              agentLogo={agentLogo}
              {...oddEvenClasses}
            />
          );
        })}
      </List>
    );
  }
}

const MessageList = ({ scid, agentLogo, onLastFetch, isConnected }) => {
  const classes = messageListStyle();

  const { loading, error, data, fetchMore, subscribeToMore } = useQuery(GET_MESSAGES, {
    variables: {
      scid,
    },
  });

  useEffect(() => {
    if (!isConnected) return console.log('waiting connection...')

    console.log("Setting interval...");

    const i = setInterval(() => {
      fetchMore({
        variables: {
          scid,
          offset: data?.messages?.length || 0,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          console.log("fetchMoreResult", fetchMoreResult)

          if (!fetchMoreResult) return prev;

          // if the fetchMoreResult's messages property is not empty, run callback onLastFetch
          // this will invoke the useEffect in the chat file and will set the timeout to
          // finish the conversation after the time of inactivity is reached.
          if (fetchMoreResult.messages && fetchMoreResult.messages.length) {
            onLastFetch(new Date().toJSON())
          }

          return Object.assign({}, prev, {
            messages: [...prev.messages, ...fetchMoreResult.messages],
          })
        }
      })
    }, 1000 * 2.5);

    return () => clearInterval(i);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.messages?.length, isConnected])


  // Cannot use this function because it is not working with multiple server instances behind a load balancer
  // eslint-disable-next-line no-unused-vars
  const more = () =>
    subscribeToMore({
      document: SUBSCRIPTION_MESSAGE_ADDED,
      variables: { scid: scid },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data?.messageAdded) return prev;
        console.log('getting data from subscriptions');
        const message = subscriptionData?.data?.messageAdded;

        let result = Object.assign({}, prev, {
          messages: [...prev.messages, message],
        });
        return result;
      },
    });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <MessageListView
      data={data}
      subscribeToMore={() => { }}
      classes={classes}
      agentLogo={agentLogo}
    />
  );
};

export default MessageList;
