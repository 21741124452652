import React from 'react'
import PropTypes from 'prop-types';

export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError (error) {
      if (error) {
        return { hasError: true }
      }
    }

  
    componentDidCatch = (error) => {
      this.props?.onError(error)
      // console.log('componentDidCatch', error)
      // this.setState({ hasError: !!error });
    }
  
    render() {
      if (
        (
          !this.props.isConnected &&
          this.props.error &&
          !this.props.initial
        )
        || 
        (
          !this.props.isConnected &&
          this.props.error
        )
      ) {

        return (
            <div
                style={{
                  display: 'flex',
                  height: '90dvh',
                  width: '90%',
                  padding: '10px',
                  margin: 'auto',
                  marginTop: '5px',
                  boxShadow: '1px 1px 50px #c4c4c4',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
            }>
                <p
                    style={{
                      textAlign: 'center',
                      padding: '20px'
                    }}    
                >
                  Reconnecting with the server. . . .
                </p>
            </div>
          );
      }
    
      return this.props.children;
    }
}

ErrorBoundary.propTypes = {
  isConnected: PropTypes.bool
}