import gql from "graphql-tag";
import React, { useEffect } from 'react'
import { useQuery } from "react-apollo";
import { AppContext } from "../../context/app";


const GET_COMMUNICATION = gql`
    query Communication($id: ID) {
            communication(id: $id){
            id  
        }
    }  
`

export const CommnunicationStatus = () => {

    const context = React.useContext(AppContext);
    const { communicationId } = context.state;

    const { data, error, loading } = useQuery(GET_COMMUNICATION, {
        variables: {
            id: communicationId
        },
        pollInterval: 5 * 1000
    });


    useEffect(() => {
        console.log(data);
        if (data && !data.communication) {
            setTimeout(context.resetState, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) {
        return null;
    }

    if (error) {
        return error
    }

    return null;
}