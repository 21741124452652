import CircularProgress from "@material-ui/core/CircularProgress";
const SpinnerComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginTop: "0px",
      }}
    >
      <CircularProgress disableShrink style={{ color: "#4ea1d6" }} />
      <h3>Loading.... </h3>
    </div>
  );
};

export default SpinnerComponent;
