import "react-confirm-alert/src/react-confirm-alert.css";

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Attachment,
  Close as CloseIcon,
  Send as SendIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import gql from "graphql-tag";
import React, { useContext, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useIntl } from "react-intl";

import Spinner from "../components/utils/spinner";
import { AppContext } from "../context/app";
import { useCustomMutation } from "../hooks";
import logo from "../q-logo.svg";
import { getTTL, setLocalStorage } from "../tools/index";
import Form from "./Form";
import MessageList from "./MessageList/MessageList";
import { CommnunicationStatus } from "./CommunicationStatus/CommunicationStatus";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "0",
    height: "90%",
  },
  cardHeader: {
    backgroundColor: "#eeeeee",
  },
}));

const CLIENT_MESSAGE = gql`
  mutation ClientMessage($input: ClientMessageInput!) {
    clientMessage(input: $input) {
      success
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      filename
      url
    }
  }
`;

const DISPOSE_COMMUNICATION = gql`
  mutation DisposeCommunication($id: ID!, $disposition: String!) {
    disposeCommunication(id: $id, disposition: $disposition)
  }
`;

const Chat = (props) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const intl = useIntl();
  const [clientMessage] = useCustomMutation(CLIENT_MESSAGE);
  const [uploadFile] = useCustomMutation(UPLOAD_FILE);
  const [disposeCommunication] = useCustomMutation(DISPOSE_COMMUNICATION);
  const [message, setMessage] = useState("");
  const [session, setSession] = useState(true);
  const [sending, setSending] = useState(false);
  const [mediaFile, setMediaFile] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [lastMessagesFetch, setLastMessagesFetch] = useState('')
  const fileRef = useRef();

  const { scid, name, contact, instance, communicationId, question } =
    context.state;

  useEffect(() => {
    const timeToWaitInSeconds = 10 * 60 * 1000 // 10 minutes
    const timeOut = setTimeout(
      () => onConfirmClose().then(console.log),
      timeToWaitInSeconds
    )
    return () => clearTimeout(timeOut)

    // eslint-disable-next-line
  }, [lastMessagesFetch])

  const sendFinishedConversationMessage = async () => {
    await clientMessage({
      variables: {
        input: {
          scid,
          name,
          contact,
          source: "client",
          platform: "web",
          instance,
          content: {
            type: 'text',
            value: "Chat terminated by the client."
          },
          attrs: {
            contact: {
              firstName: name,
              email: contact,
              question: question,
            },
          },
        },
      },
      refetchQueries: ["GetMessages"],
    });
  }
  const onConfirmClose = async () => {
    try {

      await sendFinishedConversationMessage().catch(console.warn)

      await disposeCommunication({
        variables: {
          id: communicationId,
          disposition: "Client close",
        },
      }).catch(console.warn);

      context.resetState();

    } catch (e) {
      console.error(e);
    }
  }
  const handleCloseClick = async (e) => {
    try {
      confirmAlert({
        message: intl.formatMessage({
          id: "ExitChat",
          defaultMessage: "Exit chat?",
        }),
        buttons: [
          {
            label: intl.formatMessage({
              id: "Yes",
              defaultMessage: "Yes",
            }),
            onClick: onConfirmClose,
          },
          {
            label: intl.formatMessage({
              id: "No",
              defaultMessage: "No",
            }),
            onClick: () => { },
          },
        ],
      });
    } catch (e) {
      console.error(e);
      alert(e);
    }
  };

  const getMessageContent = async () => {
    try {
      if (mediaFile) {
        const uploaded = await uploadFile({
          variables: {
            file: mediaFile,
          },
        });

        return {
          type: mediaFile?.type || "media",
          value: uploaded.data.uploadFile.url,
        };
      } else {
        return {
          type: "text",
          value: message,
        };
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const reset = () => {
    setSending(false);
    setMediaFile(null);
    setImagePreview(null);
  };

  const handleButtonClicked = async (event) => {
    event.preventDefault();
    const isTTLexpired = await getTTL("ttl");
    if (isTTLexpired) {
      setSession(false);
    } else {
      setLocalStorage("ttl");
    }

    setSending(true);
    setMessage("");

    try {
      const messageContent = await getMessageContent();

      if (!messageContent) {
        throw Error("error!");
      }

      await clientMessage({
        variables: {
          input: {
            scid,
            name,
            contact,
            source: "client",
            platform: "web",
            instance,
            content: messageContent,
            attrs: {
              contact: {
                firstName: name,
                email: contact,
                question: question,
              },
            },
          },
        },
        refetchQueries: ["GetMessages"],
      });

      reset();
    } catch (e) {
      reset();
      alert(e);
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleButtonClicked(e);
    }
  };

  const validateFile = (file) => {
    if (file) {
      const { size, type } = file;
      const mbSize = size / 1024 / 1024;

      //Size limits in MB
      if (type.match(/video/gi) && mbSize >= 60) {
        return null;
      }

      if (mbSize >= 10) {
        return null;
      }

      return file;
    }

    return null;
  };

  const handleFileChange = (event) => {
    const url = URL.createObjectURL(event.target.files[0]);
    const [file] = event.target.files;

    setImagePreview(url);
    setMediaFile(validateFile(file));
  };

  const handleFileClick = (event) => {
    fileRef.current.click();
  };

  const handleRemoveFileClick = (event) => {
    reset();
  };

  return (
    <React.Fragment>
      {session ? (
        <React.Fragment>
          <Container>
            <Card className={classNames("form", classes.content)}>
              <CardHeader
                className={classes.cardHeader}
                avatar={<Avatar src={props.conversationLogo ?? logo} />}
                subheader={name}
                title={props.headerTitle ?? "Live chat with OmniQ"}
                action={
                  <IconButton size="small" onClick={handleCloseClick}>
                    <CloseIcon />
                  </IconButton>
                }
              />
              <CardContent
                style={{margin: 0, padding: 0}}
              >
                <MessageList
                  isConnected={props.isConnected}
                  agentLogo={props.agentLogo}
                  key={communicationId}
                  scid={scid}
                  communication={communicationId}
                  onLastFetch={setLastMessagesFetch}
                />
              </CardContent>
              {sending ? <Spinner /> : null}
              <CardActions>
                <input
                  type="file"
                  ref={fileRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  name="file"
                  accept="image/*,audio/*,video/*,.pdf"
                />
                {mediaFile ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                    }}
                  >
                    <Typography
                      style={{ fontSize: "15px", width: "100%" }}
                      noWrap
                    >
                      {mediaFile.name}
                    </Typography>
                    <img
                      src={imagePreview}
                      alt="imagePreview"
                      style={{ width: "200px", height: "130px" }}
                    />
                  </div>
                ) : (
                  <TextField
                    className="inputRounded"
                    variant="outlined"
                    size="small"
                    autoFocus
                    onKeyDown={onKeyDownHandler}
                    placeholder={intl.formatMessage({
                      id: "Message",
                      defaultMessage: "Message",
                    })}
                    fullWidth
                    onChange={(e) => setMessage(e.target.value)}
                    multiline
                    value={message || ""}
                  />
                )}
                {mediaFile ? (
                  <IconButton size="small" onClick={handleRemoveFileClick}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton size="small" onClick={handleFileClick}>
                    <Attachment />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={handleButtonClicked}
                  disabled={(!message && !mediaFile) || sending}
                >
                  <SendIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Container>
          <CommnunicationStatus />
        </React.Fragment>
      ) : (
        <Form />
      )}
    </React.Fragment>
  );
};

export default Chat;
