import '../css/form.css';

import { Box, Button, Grid, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { QuestionAnswer } from '@material-ui/icons';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import uuidv1 from 'uuid/v4';

import { AppContext } from '../context/app';
import { useCustomMutation } from '../hooks';
import { setLocalStorage, validateEmail } from '../tools';
import Title from './utils/title';

const useStyle = makeStyles((theme) => ({
  btnStart: {
    width: '100%',

    borderRadius: 20,
    marginTop: 'calc(20% + 20px)',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
  },
  logo: {
    width: '100%',
  },
  questionT: {
    width: '100%',
    height: 'auto',
    paddingTop: '25px',
    paddingLeft: '10px',
    paddingBottom: '20px',
    boxsizing: 'border-box',
    background: 'none',
    outline: 'none',
    resize: 'none',
    border: 0,
    fontfamily: "'Montserrat',sans-serif",
    transition: 'all .3s',
    borderbottom: '2px solid #bebed2',
    overflow: 'hidden',
    lineheight: '1.5em',
  },
  form: {
    width: '340px',
    height: '440px',
    background: '#FFF',
    borderRadius: '8px',
    boxShadow: '0 0 5px 0px #333',
    margin: 'calc(50vh - 220px) auto',
    padding: '20px 30px',
    maxWidth: 'calc(100vw - 40px)',
    boxSizing: 'border-box',
    fontFamily: "'Montserrat',sans-serif",
    position: 'relative',
  },
  formText: {
    color: '#303F9F',
  },
  input: {
    width: '100%',
    paddingTop: '20px',
    paddingLeft: '10px',
    paddingBottom: '10px',
  },
}));

const CLIENT_MESSAGE = gql`
  mutation ClientMessage($input: ClientMessageInput!) {
    clientMessage(input: $input) {
      success
      communication {
        id
      }
      client {
        token
      }
    }
  }
`;

export default function Form(props) {
  const { logo } = props;
  const [loading, setLoading] = useState(false);
  //const localStorage = window.localStorage;
  const classes = useStyle();
  const context = useContext(AppContext);
  const intl = useIntl();

  const [clientMessage] = useCustomMutation(CLIENT_MESSAGE);

  const { state, updateState, handleInputChange } = context;
  const { name, contact, question, instance } = state;

  const handleButtonSubmitted = async (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail(contact);

    if (isEmailValid) {
      if (name && contact && question) {
        setLoading(true);

        const scid = uuidv1();

        try {
          const response = await clientMessage({
            variables: {
              input: {
                scid,
                name,
                contact,
                source: 'client',
                platform: 'web',
                instance,
                content: {
                  type: 'text',
                  value: question,
                },
                attrs: {
                  contact: {
                    firstName: name,
                    email: contact,
                  },
                },
              },
            },
          });

          console.log(response.data.clientMessage);

          const {
            clientMessage: { client, communication },
          } = response.data;

          const expires = new Date();
          expires.setTime(Date.now() + 1000 * 60 * 10);
          const token = client.token;
          const communicationId = communication.id;
          setLocalStorage('session_metadata', {
            communicationId,
            token,
            scid,
            name,
            contact,
          });

          updateState({
            token: token,
            communicationId: communication.id,
            scid,
            messageText: '',
            initial: false,
          });
        } catch (e) {
          setLoading(false);
          console.error(e);

          alert(
            intl.formatMessage({
              id: 'StartChatError',
              defaultMessage: 'Cannot create chat',
            })
          );
        }
      } else {
        alert(
          intl.formatMessage({
            id: 'FieldsRequired',
            defaultMessage: 'Cannot create chat',
          })
        );
      }
    }
  };

  return (
    <form className={`${classes.form} custom-form`}>
      <Grid item xs={12}>
        {logo ? (
          <>
            <img src={logo} className={classes.logo} alt="companyLogo"></img>
          </>
        ) : (
          <>
            <QuestionAnswer
              fontSize="large"
              style={{
                position: 'relative',
                fill: '#FFFFFF',
                float: 'left',
                bottom: '13px',
                marginRight: 8,
                background: '#303f9f',
                padding: '8px',
                borderRadius: 50,
              }}
            />
            <Title
              text={intl.formatMessage({
                id: 'Title',
                defaultMessage: 'OmniQ Chat',
              })}
              className={`${classes.formText} form-text`}
            />
          </>
        )}
      </Grid>
      <Input
        autoFocus
        placeholder={intl.formatMessage({
          id: 'Name',
          defaultMessage: 'Name',
        })}
        value={name || ''}
        onChange={handleInputChange}
        className={`${classes.input} form-input`}
        name="name"
        type="text"
      />
      <Input
        placeholder={intl.formatMessage({
          id: 'Email',
          defaultMessage: 'Email',
        })}
        className={`${classes.input}`}
        value={contact || ''}
        onChange={handleInputChange}
        name="contact"
        type="text"
      />

      <Input
        fullWidth
        placeholder={intl.formatMessage({
          id: 'Question',
          defaultMessage: 'Question',
        })}
        multiline
        value={question}
        onChange={handleInputChange}
        className={classes.questionT}
        name="question"
        type="text"
      />

      <Box>
        <Button
          className={`${classes.btnStart} start-button`}
          variant="contained"
          color="primary"
          onClick={handleButtonSubmitted}
          disabled={loading}
        >
          <FormattedMessage id="StartChat" defaultMessage="Chat" />
        </Button>
      </Box>
    </form>
  );
}
