import { makeStyles } from "@material-ui/core";

export const messageListStyle = makeStyles((theme) => ({
  root: {
    height: "55vh",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  messageContainer: {
    backgroundColor: "#efefef",
    borderRadius: "15px",
    padding: "5px 10px",
    maxWidth: "300px",
    minWidth: "50px",
    textAlign: "left",
    marginRight: "auto",
    margin: "5px 0",
  },
  messageContainerButtons: {
    backgroundColor: "#efefef",
    borderRadius: "15px",
    padding: "5px 10px",
    maxWidth: "250px",
    minWidth: "50px",
    textAlign: "left",
    marginRight: "auto",
    margin: "5px 0",
  },
  messageContainerRight: {
    borderRadius: "15px",
    padding: "5px 10px",
    maxWidth: "300px",
    minWidth: "50px",
    textAlign: "left",
    marginLeft: "auto",
    margin: "5px 0",
  },
  messageImage: {
    maxWidth: "300px",
    minWidth: "50px",
    textAlign: "left",
    marginLeft: "auto",
    margin: "5px 0",
  },
  fontLeft: {
    wordBreak: "keep-all",
    color: "#5a5a6e",
    fontSize: "15px",
  },
  fontRight: {
    wordBreak: "keep-all",
    color: "white",
    fontSize: "15px",
  },
  flexEnd: {
    marginLeft: "5px",
  },

  listItem: {
    display: "inline-flex",
    margin: 0,
    maxWidth: "100%",
    minWidth: "fit",
    padding: 0,
  },

  case: {
    background: "#e0e0e0",
    borderRadius: "20px",
    textAlign: "center",
  },

  buttons: {
    wordBreak: "keep-all",
    width: "auto",
    height: "auto",
    padding: "5px",
    lineHeight: 1.3,
    margin: "5px 5px",
    textTransform: "none",
    backgroundColor: "Transparent",
    borderRadius: "15px",
    borderColor: "#efefef",
    border: "1px solid #efefef",
    color: "#8bc34a",
    background: "none",
    boxShadow: "none",
    fontSize: "16px",
  },

  font: {
    color: "#5a5a6e",
    width: "auto",
    fontSize: "15px",
  },
  logoMark: {
    backgroundColor: "transparent",
    borderRadius: "80%",
    padding: "5px 5px",
    backgroundSize: "contain",
    maxWidth: "fit",
    minWidth: "fit",
    textAlign: "left",
    marginRight: "auto",
    margin: "5px 0",
  },
  messageContainerT: {
    backgroundColor: "transparent",
    borderRadius: "15px",
    padding: "5px 0px",
    minWidth: "40px",
    maxWidth: "300px",
    textAlign: "left",
    marginRight: "auto",
  },
}));
