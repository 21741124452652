import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";

// You should use an absolute URL here
const options = {
  httpUri: process.env.REACT_APP_GRAPHQL_API || "http://localhost:4000/graphql",
  wsUri: process.env.REACT_APP_GRAPHQL_WS_API || "ws://localhost:4000/graphql",
};

let httpLink = createUploadLink({
  uri: options.httpUri,
});
const authLink = setContext((_, { headers }) => {
  const params = new URLSearchParams(window.location.search);
  const tenantId = params.get("tenantId");

  return {
    headers: {
      ...headers,
      "x-tenant-id": `${tenantId}`,
    },
  };
});
// Create the subscription websocket link if available

export const subscriptionClient = new SubscriptionClient(options.wsUri, {
  reconnect: true,
  connectionParams: () => {
    return {
      authToken: JSON.parse(localStorage.getItem("session_metadata"))
        ?.metadata.token,
    };
  },
});

if (options.wsUri) {
  const wsLink = new WebSocketLink(subscriptionClient);
  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  httpLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
}

export const cache = new InMemoryCache();

// Create the apollo client instance
export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  connectToDevTools: true,
});
